/* =====
NATIVE CSS
===== */

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #05000F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ===== CUSTOM CSS ===== */

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #05000F;
  color: #F5F5FF;
}


/* SECTION */
section {
  width: 90%;
  max-width: 60rem;
  margin: 2rem auto;
}

.centered {
  display: flex;
  justify-content: center;
  margin: auto 0;
}

.on-left {
  display: flex;
  justify-content: end;
}


/* TEXT CONTAINER */
.text-container {
  width: 100%;
  max-width: 50rem;
  margin: 1.5rem auto;
}

/* ===== FONT ===== */
/* HEADING */
h1.responsive {
  margin: 0;
  background: var(--Secondary-Gradients-Linear-Horizontal-H-Bleu, linear-gradient(90deg, #0BF 0%, #6255BB 100%));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mobile h1.responsive {
  font-size: 3rem;
}

.tablet h1.responsive {
  font-size: 4rem;
}

.laptop h1.responsive {
  font-size: 5rem;
}

.desktop h1.responsive {
  font-size: 6rem;
}

h2.responsive {
  font-size: 4.5rem;
}

.mobile h2.responsive {
  font-size: 2rem;
}

.tablet h2.responsive {
  font-size: 2.66rem;
}

.laptop h2.responsive {
  font-size: 3.33rem;
}

.desktop h2.responsive {
  font-size: 4.5rem;
}

p {
  margin: 1rem 0;
  font-size: 1rem;
}

.xs {
  font-size: 0.75rem;
}

.lg {
  font-size: 1.25rem;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.no-margin {
  margin: 0;
}

.orange {
  color: #D38C64;
}

.center {
  text-align: center;
}

/* HYPERLINKS */
a {
  text-decoration: none;
  color: #F5F5FF;
}

a:hover {
  color: #D38C64;
}

/* ===== BUTTONS ===== */
button {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #05000F;
  color: #F5F5FF;
  border: none;
  border-radius: 1vh;
  padding: 1rem 2rem;
  cursor: pointer;
  max-width: max-content;
  margin: 0 auto;
}

button:hover {
  background-color: #F5F5FF;
  color: #D38C64;
  box-sizing: border-box;
}

.primary-button {
  background-color: #D38C64;
  border: none;
}

.secondary-button {
  background-color: #05000F;
  border: 2px solid #F5F5FF;
  padding: 0.9rem 1.8rem;
}

/* ===== IMAGES ===== */
.icon {
  color: #F5F5FF;
}

.icon:hover {
  color: #D38C64;
}

img[src$=".jpg"],
img[src$=".png"] {
  border-radius: 1vh;
}

/* ===== ANIMATIONS ===== */
@keyframes image-mouse-hover {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.025);
  }
}

@keyframes image-mouse-unhover {
  0% {
    transform: scale(1.025);
  }

  100% {
    transform: scale(1);
  }
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8rem;
  height: 8rem;
}

/* ===== TABLE ===== */
table {
  width: 100%;
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

th,
td {
  padding: 1rem;
  border-bottom: 2px solid #F5F5FF;
}

/* ===== HEADER ===== */
header {
  z-index: 1000;
  position: sticky;
  top: 0;
  margin: 0 auto 2rem auto;
  background-color: #05000F;
  display: flex;
  align-items: center;
  height: 5rem;
  z-index: 1000;
}

.logo-header {
  width: 15rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ===== OVERLAY ===== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Couleur de fond assombrie */
  backdrop-filter: blur(1vh);
  /* Flou de l'arrière-plan */
  z-index: 999;
  /* Z-index inférieur à celui du header */
}

.fade-in {
  animation: fadeIn 0.3s;
  /* Animation pour l'apparition */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ===== DROPDOWN MENU ===== */
.dropdown-icon-container {
  padding-left: 2rem;
}

.dropdown-icon-container:hover {
  cursor: pointer;
  color: #D38C64;
}

.dropdown.open .dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem 0;
}

.dropdown-menu {
  position: absolute;
  background-color: #05000F;
  display: none;
  width: 100%;
  z-index: 100;
}

.dropdown-menu-button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* ===== HERO ===== */
.hero-image {
  height: 30rem;
  background-size: cover;
  background-position: center;
  align-items: center;
  border-radius: 1vh;
  margin: 0 auto 0;
}

.desktop .text-container.hero {
  margin-top: -7rem;
}

.laptop .text-container.hero {
  margin-top: -6rem;
}

.tablet .text-container.hero {
  margin-top: -5rem;
}

.mobile .text-container.hero {
  margin-top: -3.5rem;
}

/* CTA CONTAINER */
.cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.mobile .cta-container {
  flex-direction: column;
}

/* ===== CHARACTER CARDS ===== */
.character-card-list {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 1rem;
  width: 100%;
}

.character-card {
  position: relative;
  width: 8rem;
  height: 30rem;
  background-size: cover;
  background-position: center;
  border-radius: 1vh;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.character-card:hover {
  animation: image-mouse-hover 0.15s ease forwards;
}

.character-card.no-hover {
  animation: image-mouse-unhover 0.15s ease forwards;
}

.character-card .character-card-overlay {
  position: absolute;
  mix-blend-mode: multiply;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.character-card .character-card-content {
  position: relative;
  text-align: center;
  color: white;
  padding: 2vh 1vh;
}

.tablet .character-card-list,
.mobile .character-card-list {
  flex-direction: column;
}

.tablet .character-card,
.mobile .character-card {
  width: 100%;
  height: 6rem;
  background-position: 0 40%;
  text-align: right;
}

.tablet .character-card .character-card-content,
.mobile .character-card .character-card-content {
  width: 100%;
  margin: auto 1rem auto auto;
  padding: 0;
  text-align: right;
}

/* ===== CHAPTER CARDS ===== */
.chapters-list {
  display: flex;
  width: 100%;
  max-width: 50rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 1rem;
}

.chapters-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 1vh;
}

.chapters-card:hover {
  background-color: #11111F;
  animation: image-mouse-hover 0.15s ease forwards;
}

.chapters-card.no-hover {
  background-color: none;
  animation: image-mouse-unhover 0.15s ease forwards;
}

.chapters-card-image {
  width: 25rem;
  height: 8rem;
  background-size: cover;
  background-position: center;
  border-radius: 1vh;
  margin-right: 1rem;
}

.chapters-card-content {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  width: 100%;
}

.chapters-bottom-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* ===== FOOTER ===== */
.logo-footer {
  width: 3rem;
}

.footer-container {
  margin: 5rem auto 0 auto;
  background-color: #11111F;
  color: #F5F5FF;
  padding: 3rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.footer-links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  gap: 2rem;
}

.footer-links-rgpd-container {
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-links-social-container {
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.mobile .footer-links-container {
  flex-direction: column;
  align-items: center;
}

/* ========== CONTACT ========== */
/* .form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 50rem;
  margin: 1.5rem auto;
} */

.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 30rem;
  margin: 1.5rem auto;
}

.input-field {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

label {
  font-weight: 700;
}

input,
textarea {
  font-family: 'Lato', sans-serif;
  padding: 1rem;
  border: none;
  border-radius: 1vh;
  font-size: 1rem;
  background-color: #F5F5FF;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

img.image-contact {
  width: 50%;
  object-fit: cover;
  background-color: transparent;
}

.tablet img.image-contact,
.mobile img.image-contact {
  width: 100%;
}

/* ========== READ.JS ========== */
/* ===== CHAPTER PREVIEW ===== */
.chapters-preview {
  margin: auto;
  text-align: center;
  width: max-content;
  border-radius: 1vh;
  color: #05000F;
  background-color: #F5F5FF;
}

.fade-to-black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  animation: fadeToBlack 5s ease forwards;
  animation-delay: 1s;
}

@keyframes fadeToBlack {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

/* ===== READ CHAPTER ===== */

.image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-container.fade-out img {
  animation: fadeOut 2s forwards;
}

/* ========== TO BE CONTINUED ========== */
.to-be-continued {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden-footer {
  display: none;
}

/* ========== SELECT ========== */
.chapters-container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-direction: row-reverse;
  align-items: start;
}

.tablet .chapters-container,
.mobile .chapters-container {
  flex-direction: column;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #05000F;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 10;
}

.tablet .filter-container,
.mobile .filter-container {
  flex-direction: row;
  align-items: center;
  padding: 0 0 1rem 0;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
}

.filter-container::-webkit-scrollbar {
  display: none;
}

select {
  background-color: #11111F;
  color: #F5F5FF;
  border: none;
  padding: 1rem;
  border-radius: 1vh;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.sticky {
  position: sticky;
  top: 9rem;
}

.tablet .sticky,
.mobile .sticky {
  top: 5rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}